import Head from "next/head";
import { productCarouselSchema } from "../src/constants/carouselSchema";
import {
  mahindraImagePacks,
  piaggioImagePacks,
} from "../src/constants/imagePacks";
import { peopleAlsoAskSchema } from "../src/constants/peopleAlsoAskSchema";
import {
  mahindraPrdouctSchema,
  piaggioPrdouctSchema,
} from "../src/constants/productSchema";

import LoanFormPopupV2 from "../src/Drawer/LoanFormPopupV2";
import { MemoizedConnectDrawer } from "../src/Drawer/connectDrawer";
import { MemoizedCityPopup } from "../src/Popups/cityPopup/cityPopup";
import {
  articleSchema,
  organizationSchema,
  searchWebSite,
  webPageSpeakable,
} from "../src/constants/structuredFAQ";
import { MemoizedFooter } from "../src/layouts/footer";
import BookVehicleDrawer from "../src/modules/Home/Drawer/bookVehicleDrawer";
import CarouselDrawer from "../src/modules/Home/Drawer/carouselDrawer";
import FullscreenDrawer from "../src/modules/Home/HomePageRevamp/VehicleSlideDrawer";
import QlDrawerComponent from "../src/modules/LeadDataCollection/DrawerComponents/LoanDrawerComponents";
import { MemoizedHome } from "../src/pages/Home";

export default function Home() {
  return (
    <>
      <Head>
        <title>
          Turno - India's Best Electric Commercial Vehicle Marketplace
        </title>
        <meta
          name="description"
          content="Buy an Electric load gadi from Brands like Piaggio, Mahindra, ETrio, Altigreen, OSM . Get commercial vehicle details such as features and prices in India."
        />
        <meta
          name="keywords"
          content="Turno, EV, EVs, electric auto, ev in india, electric auto rickshaw, auto gadi, auto, auto rickshaw, auto india, Light Commercial Vehicle, commercial vehicle, small commercial vehicles"
        />
        <meta name="author" content="Turno" />
        <meta name="url" content="https://www.turno.club/" />
        <meta name="robots" content="INDEX,ARCHIVE,FOLLOW" />
        <meta name="lang" content="en" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Facebook */}
        <meta property="og:url" content="https://www.turno.club/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Turno - The best place to buy load three wheeler electric auto in India"
        />
        <meta
          property="og:description"
          content="Want to buy commercial electric three wheeler vehicle? Get the best price and loan / financing for your commercial electric vehicle from Turno. Book a free test drive for your EV today on turno.club"
        />
        <meta property="og:image" content="/logoIcon.png" />
        <meta property="fb:app_id" content="5796260583761767" />
        <meta property="fb:pages" content="646488286564232" />
        {/* Twitter */}
        <meta
          name="twitter:description"
          content="Want to buy commercial electric three wheeler vehicle? Get the best price and loan / financing for your commercial electric vehicle from Turno. Book a free test drive for your EV today on turno.club."
        />
        <meta
          name="twitter:title"
          content="Turno- The best place to buy load three wheeler electric auto in India"
        />
        <meta name="twitter:site" content="@Turno" />
        <meta name="twitter:creator" content="@Turno" />
        <meta name="twitter:url" content="https://www.turno.club/" />
        <meta
          name="twitter:app:id:googleplay"
          content="com.customerapp.turno.turno_customer_application"
        />
        <meta name="twitter:app:country" content="IN" />
        {/* Google store verification meta information */}
        <meta name="google-site-verification" content="Z4WHXzpop_N_b6hE5g7A0fCENRwj5VqC3nSEsXLaQ3Y" />

        <link rel="canonical" href="https://www.turno.club/" />
        <link rel="icon" href="/favicon.png" />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(peopleAlsoAskSchema),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(articleSchema) }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationSchema),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(webPageSpeakable) }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(searchWebSite) }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(piaggioPrdouctSchema),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(mahindraPrdouctSchema),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(piaggioImagePacks),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(mahindraImagePacks),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(productCarouselSchema),
          }}
        />
      </Head>

      {/* <MemoizedHeader /> */}
      <MemoizedHome />
      <QlDrawerComponent />

      <MemoizedCityPopup />
      <BookVehicleDrawer />
      <CarouselDrawer />
      <FullscreenDrawer />

      <MemoizedConnectDrawer
        heading={"Talk to Us"}
        subHeading={
          "We help you find the best vehicle for your business with affordable financing. Connect with us now."
        }
        ctaText={"Call us"}
      />

      {/* V2_State_Test */}
      {/* <LoanFormPopup /> */}

      <LoanFormPopupV2 />
      <MemoizedFooter />
    </>
  );
}
