import { Box, Typography, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { cityArray, imagesList } from "../../constants";
import { Context } from "../../context/context";
import { sendAnalytics } from "../../utils/analyticsCommon";

const CityPopupDialog = styled(Box)(({ theme }) => ({
  ".city-images": {
    width: "100%",
  },
  ".text": {
    marginBottom: "10px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineSeight: "130%",
    textAlign: "center",
    color: "#5d5bc0",
  },

  [theme.breakpoints.down("md")]: {
    ".text": {
      fontSize: "20px",
    },
  },
}));

const CityPopup = () => {
  const state = useContext(Context);
  let open = state.showCityPopupValue;
  const { hideCityPopup, changeCity } = useContext(Context);
  const navigate = useRouter();
  const cityHandle = (city) => {
    changeCity(city);
    let pathname = "all";
    if (city !== "Others") pathname = city?.toLowerCase();

    // let pathname = "electric-auto-india";
    // if (city === "Bangalore") {
    //   pathname = "electric-auto-bangalore";
    // } else if (city === "Hyderabad") {
    //   pathname = "electric-auto-hyderabad";
    // } else if (city === "Mumbai" || city === "Pune") {
    //   pathname = "electric-auto-mumbai-pune";
    // } else if (city === "Chennai") {
    //   pathname = "electric-auto-chennai";
    // }

    navigate.push(`/electric-commercial-vehicles`);

    hideCityPopup();
    const payload = {
      city_selected: city,
    };

    sendAnalytics("city_selected", payload, "en");
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        hideCityPopup();
        sendAnalytics("city_popup_dismissed", {}, "en");
      }}
    >
      <DialogContent>
        <CityPopupDialog>
          <Box>
            <Typography className="text">Electric auto in</Typography>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 3, sm: 1, md: 2 }}
            >
              {cityArray?.map((city) => {
                return (
                  <Grid item xs={4} sm={3} key={city?.name}>
                    <LazyLoadImage
                      className="city-images"
                      alt={"vehicles"}
                      effect="opacity"
                      src={imagesList[city?.imageName]}
                      onClick={() => cityHandle(city?.name)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CityPopupDialog>
      </DialogContent>
    </Dialog>
  );
};
export const MemoizedCityPopup = React.memo(CityPopup);
