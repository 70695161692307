import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { mapStates } from "../../constants/mapRegions";
import { Context } from "../../context/context";
import LeadService from "../../services/leadService";
import { validLeadConvert } from "../../services/leadValidation";
import sessionTracking from "../../services/sessionTracking";
import { userQlFlow } from "../../services/userQLFlow";
import { sendAnalytics } from "../../utils/analyticsCommon";

const useLoanFormPopupV2 = (fixState) => {
  const state = useContext(Context);
  const {
    showSnackbar,
    hideLoanPopup,
    updateUserQlData,
    showReviewStoryDrawer,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const navigate = useRouter();
  //   const [vehicleDetails, setVehicleDetails] = useState({});
  //   let cacheInfo = getCookie("user_verified")
  //     ? JSON.parse(getCookie("user_verified"))
  //     : "";
  const leadIntent = "HIGH_INTENT";

  const form = useFormik({
    validateOnChange: true,
    initialValues: {
      mobile: "",
      state: fixState ? fixState : "none",
      checkbox: true,
    },

    validate: (values) => {
      const errors = { ...values?.errors };

      if (!values?.mobile?.toString()) {
        errors.mobile = "Enter valid number";
      }
      if (values?.mobile?.toString()?.length !== 10) {
        errors.mobile = "Enter valid number";
      }
      if (values?.mobile && values?.mobile[0] < 5) {
        errors.mobile = "Enter valid number";
      }

      const ipInformation = localStorage.getItem("geoLocation")
        ? JSON.parse(localStorage.getItem("geoLocation"))
        : {};

      if (values?.mobile?.toString()?.length === 10) {
        let payload = {
          phoneNumber: values?.mobile,
          state: mapStates[ipInformation?.countryRegion] ?? "Others",
          type: state?.loanPopupTrigger?.formTrigger,
          cityInfo: ipInformation?.city,
          geoInfo: ipInformation,
        };
        sendAnalytics("v2_lead_form_entered_mobile", payload, "en");

        // Need to check for Unknown logic

        const leadPayload = {
          name: "",
          phone: values?.mobile,
          state: mapStates[ipInformation?.countryRegion] ?? "Others",
          leadIntent: leadIntent,
          subSource: "Store form",
        };

        // param: leadpayload, analyticsPayload
        validLeadConvert(leadPayload, payload);
      }

      if (values?.state === "none") {
        //errors.state = "Select State";
        errors.state = "Select State";
      }
      return errors;
    },

    onSubmit: (values) => {
      const analyticsPayload = {
        phone_number: values?.mobile,
        name: "",
        state: values?.state,
        button_trigger_location: state?.loanPopupTrigger?.formTrigger,
        // state?.loanPopupTrigger === "v2_vehicle_Loan"
        //   ? "v2_loan_card"
        //   : state?.loanPopupTrigger === "v2_emi_value_vehicle"
        //   ? "v2_emi_value_vehicle"
        //   : "v2_view_vehicle",
        type: state?.loanPopupTrigger?.formTrigger,
        // state?.loanPopupTrigger === "v2_vehicle_Loan"
        //   ? "v2_loan_card"
        //   : state?.loanPopupTrigger === "v2_emi_value_vehicle"
        //   ? "v2_emi_value_vehicle"
        //   : "v2_view_vehicle",
      };

      sendAnalytics("reserve_vehicle_button_clicked", analyticsPayload, "en");
      const payload = {
        name: "",
        phone: values?.mobile,
        pan: "",
        pincode: "",
        dob: "",
        state: values?.state,
        optedExchange: "",
        language: "",
        refNumber: "",
        leadIntent: leadIntent,
        subSource:
          state?.loanPopupTrigger?.formTrigger === "v2_video_call_clicked"
            ? "Video Call Initiated"
            : "Lead Form",
        verified: state?.leadVerifiedStatus,
      };

      setLoading(true);

      // Refactor_code

      LeadService(payload)
        .then((response) => {
          const sessionInfo = {
            leadUuid: response?.data?.uuid,
            newSession: true,
            formSubmitted: true,
            leadIntent: "LOW_INTENT",
          };

          sessionTracking(sessionInfo);
          showSnackbar("We will get back to you soon!!", "success");
          setLoading(false);
          hideLoanPopup();
          if (
            state?.loanPopupTrigger?.formTrigger === "v2_vehicle_Loan" ||
            state?.loanPopupTrigger?.formTrigger === "v2_emi_value_vehicle" ||
            state?.loanPopupTrigger?.formTrigger === "v2_video_call_clicked"
          ) {
            sendAnalytics(
              "v2_loan_card_lead_form_closed",
              {
                type: "v2_loan_card",
              },
              "en"
            );
            state?.loanPopupTrigger?.formTrigger === "v2_video_call_clicked"
              ? ""
              : !state?.loanPopupTrigger?.skipFinancePage && navigate.push("/finance");
          } else if (
            state?.loanPopupTrigger?.formTrigger === "v2_charging_station"
          ) {
            sendAnalytics(
              "v2_charging_station_lead_form_closed",
              {
                type: state?.loanPopupTrigger?.formTrigger,
              },
              "en"
            );

            navigate.push("/chargingStation");
          } else if (state?.loanPopupTrigger?.formTrigger === "v2_tco_form") {
            sendAnalytics(
              "v2_tco_lead_form_closed",
              {
                type: state?.loanPopupTrigger?.formTrigger,
              },
              "en"
            );

            navigate.push("tco/tco-details");
          } else {
            sendAnalytics(
              "v2_loan_card_lead_form_closed",
              {
                type: state?.loanPopupTrigger?.formTrigger,
                // state?.loanPopupTrigger === "v2_emi_value_vehicle"
                //   ? "v2_emi_value_vehicle"
                //   : "v2_view_vehicle",
              },
              "en"
            );

            if (state?.loanPopupTrigger?.formTrigger === "v2_vehicle_Loan") {
              navigate.push(`/electric-commercial-vehicles/Greaves/Eltra`);
            } else if (
              navigate.pathname.includes("/ev-myth-buster") ||
              navigate.pathname.includes("/ice-vs-ev") ||
              navigate.pathname.includes("/goods-ev") ||
              navigate.pathname.includes("/battery-buyback")
            ) {
              navigate.push(`/electric-commercial-vehicles`);
            }else if(state?.loanPopupTrigger?.formTrigger === "v2_view_vehicle_newLaunch_ev_model"){
              navigate.push(`/electric-passenger-vehicles/${state?.loanPopupTrigger?.brand}/${state?.loanPopupTrigger?.vehicle_id}`);
            } 
            else if(state?.loanPopupTrigger?.formTrigger === "v2_view_vehicle_newLaunch_ev_model_4w"){
              navigate.push(`/electric-commercial-vehicles/4w/${state?.loanPopupTrigger?.brand}/${state?.loanPopupTrigger?.vehicle_id}`);
            }
            else if(state?.loanPopupTrigger?.formTrigger === "v2_view_vehicle_listing"){

              const navigationUrl = state?.loanPopupTrigger?.vehicle_type === "passenger"
                ? `/electric-passenger-vehicles/${state?.loanPopupTrigger?.brand}/${state?.loanPopupTrigger?.vehicle_id}` :
                (
                  state?.loanPopupTrigger?.vehicle_type === "cargo4wheeler"
                 ? `/electric-commercial-vehicles/4w/${state?.loanPopupTrigger?.brand}/${state?.loanPopupTrigger?.vehicle_id}`
                  : `/electric-commercial-vehicles/${state?.loanPopupTrigger?.brand}/${state?.loanPopupTrigger?.vehicle_id}`
                )


              navigate.push(navigationUrl);

            }
            else if(state?.loanPopupTrigger?.formTrigger === "home_page_vehicle_card_click"){
              navigate.push(state?.loanPopupTrigger?.redirectLink);
            }
            else if(state?.loanPopupTrigger?.formTrigger === "book_test_drive_form" || state?.loanPopupTrigger?.formTrigger === "home_page_emi_card_click"){
              navigate.push(`/electric-commercial-vehicles`);
            }
            else {
              console.log("navigate");
              const Paths = [
                "passenger-vehicles",
                "goods-electric-commercial",
                "electric-passenger",
                "3w-ev-emi-11k-passenger",
                "new-homepage",
              ];
              const isPathMatched = Paths.some(path => navigate.pathname.includes(path));
              if (navigate.pathname !== "/vehicles" && !isPathMatched) {
                navigate.push(
                  `/electric-commercial-vehicles/${state?.loanPopupTrigger?.brand}/${state?.loanPopupTrigger?.vehicle_id}`
                );
              }
            }
          }

          // Enable QL Lead flow
          
          if(state?.loanPopupTrigger?.vehicle_type !=="passenger"){
            userQlFlow(state, updateUserQlData, showReviewStoryDrawer);
          }
             
        })
        .catch((error) => {
          showSnackbar(
            "Oops, something went wrong. Please try again later.",
            "error"
          );
        });
    },
  });
  return {
    form,
    loading,
  };
};

export default useLoanFormPopupV2;
