import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useContext, useState } from "react";

import { mapStates } from "../../../constants/mapRegions";
import { Context } from "../../../context/context";
import LeadService from "../../../services/leadService";
import { validLeadConvert } from "../../../services/leadValidation";
import sessionTracking from "../../../services/sessionTracking";
import { userQlFlow } from "../../../services/userQLFlow";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { getCookie } from "../../../utils/cookie";
import { myScroll } from "../../../utils/myScroll";

const UseBookEVConsultation = (fixState) => {
  const state = useContext(Context);
  const {
    showSnackbar,
    hideWhatsappPopup,
    changeState,
    updateUserQlData,
    showReviewStoryDrawer,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  let location = useRouter();
  const brand = state?.vehicleBrand;
  let vehicle_id = 4;
  if (brand === "OSM") vehicle_id = 3;
  else if (brand === "piaggio") vehicle_id = 2;
  else if (brand === "mahindra") vehicle_id = 1;

  const leadIntent = "HIGH_INTENT";

  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  const ipInformation =
    typeof localStorage !== "undefined"
      ? localStorage?.getItem("geoLocation")
        ? JSON.parse(localStorage?.getItem("geoLocation"))
        : {}
      : {};

  const form = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: "",
      state: fixState ? fixState : "none",
      mobile: cacheInfo?.phone ?? "",
      otp: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values?.mobile?.toString()) {
        errors.mobile = "Enter Valid Number";
        //errors.mobile = "";
      }
      if (values?.mobile?.toString()?.length !== 10) {
        errors.mobile = "Enter Valid Number";
        //errors.mobile = "";
      }
      if (values?.mobile && values?.mobile[0] < 5) {
        errors.mobile = "Enter Valid Number";
        //errors.mobile = "";
      }
      if (values?.state === "none") {
        //errors.state = "Select State";
        errors.state = "";
      }

      if (values?.mobile?.toString()?.length === 10) {
        let payload = {
          phoneNumber: values?.mobile,
          type: "v2_top_banner_lead_form",
          cityInfo: ipInformation?.city,
          geoInfo: ipInformation,
        };
        sendAnalytics("v2_lead_form_entered_mobile", payload, "en");

        const leadPayload = {
          name: "",
          phone: values?.mobile,
          state: mapStates[ipInformation?.countryRegion] ?? "Others",
          leadIntent: leadIntent,
          subSource: "consult form",
        };

        // param: leadpayload, analyticsPayload
        validLeadConvert(leadPayload, payload);
      }

      if (state?.showOtpModule) {
        if (!values?.otp?.toString()) {
          errors.otp = "Otp Number is Mandatory";
        }
        if (values?.otp?.toString()?.length !== 6) {
          errors.otp = "Enter Valid Otp";
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      changeState(values.state);

      let entireUrl = JSON.parse(localStorage.getItem("urlParams"))?.entire_url;
      let brandArray = [
        "mahindra",
        "piaggio",
        "osm",
        "euler",
        "altigreen",
        "etrio",
        "greaves",
      ];

      const payload = {
        name: values?.name,
        phone: values?.mobile,
        pan: "",
        pincode: "",
        dob: "",
        state: values?.state,
        optedExchange: "",
        vehicleSelected: brand,
        language: "",
        refNumber: "",
        leadIntent: leadIntent,
        subSource: "consult form",
        verified: state?.leadVerifiedStatus,
      };

      setLoading(true);

      // const analyticsPayload = {
      //   vehicle_name: brand,
      //   vehicle_id: vehicle_id,

      //   tag_id: tag_id,
      //   tag_name: tag_name,
      //   offer_id: offer_id,
      //   offer_name: offer_name,
      //   phone_number: values?.mobile,
      //   name: values?.name,
      //   state: values?.state,
      //   button_trigger_location: "ev_consult_form",
      //   type: "ev_consult_form",
      // };

      const analyticsPayloadV2 = {
        phone_number: values?.mobile,
        name: values?.name,
        state: values?.state,
        type:
          location.pathname === "/"
            ? "v2_top_banner_lead_form"
            : "happyCustomer_lead_form",
        cityInfo: ipInformation?.city,
        geoInfo: ipInformation,
      };

      sendAnalytics("reserve_vehicle_button_clicked", analyticsPayloadV2, "en");
      LeadService(payload)
        .then((response) => {
          showSnackbar("We will get back to you soon!!", "success");
          setLoading(false);
          myScroll(
            0,
            document.getElementById("top-selling-ev")?.offsetTop - 100,
            "smooth"
          );
          const sessionInfo = {
            leadUuid: response?.data?.uuid,
            newSession: false,
            formSubmitted: true,
            leadIntent: "LOW_INTENT",
          };

          // Enable QL Lead flow
          userQlFlow(state, updateUserQlData, showReviewStoryDrawer);

          sessionTracking(sessionInfo);
        })
        .catch((error) => {
          showSnackbar(
            "Oops, something went wrong. Please try again later.",
            "error"
          );
        });

      hideWhatsappPopup();
    },
  });

  return {
    form,
    loading,
  };
};

export default UseBookEVConsultation;
