import CancelIcon from "@mui/icons-material/Cancel";
import { Avatar, Box, Drawer, styled, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { imagesList, StateOptions } from "../../../constants";
import { primary } from "../../../constants/theme";
import { Context } from "../../../context/context";
import { MemoizedButton } from "../../../SDK/button";
import { MemoizedSelectDropDown } from "../../../SDK/selectDropdown/selectDropdown";
import { checkError } from "../../../SDK/utils/validationHelpers";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { getCookie } from "../../../utils/cookie";
import { myScroll } from "../../../utils/myScroll";
import { MemoizedOTP } from "../../Otp/otp";
import useCarouselDrawer from "./useCarouselDrawer";

const CustomCarouselDrawer = styled(Box)(({ theme }) => ({
  marginTop: "70px",
  marginBottom: "40px",
  padding: "1em",
  "input-field": {
    minWidth: "100%",
    width: "100% !important",
  },

  ".input-box": {
    //marginBottom: "10px",
    //marginTop: "20px",

    width: "100%",
  },
  ".select-box": {
    margin: "10px",
    marginTop: "20px",
    width: "96%",
  },
  ".button-lets-chat": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".heading": {
    fontWeight: "700",
    textAlign: "center",
    fontSize: "2em",
  },
  ".text-contact-soon": {
    textAlign: "center",
    fontSize: "1.5em",
    marginBottom: "0.5em",
  },
  ".receive-notifications": {
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "3em",
    marginRight: "3em",
  },
  ".terms-and-conditions": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    maxWidth: "542px",
    color: primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  ".check-label": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "400",
    fontSize: "12px",
  },
  ".otp-disclaimer-text": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "700",
    color: "#666666",
    fontSize: "12px",
  },
  [theme.breakpoints.down("md")]: {
    ".heading": {
      fontWeight: "700",
      textAlign: "center",
      fontSize: "1em",
    },
    ".receive-notifications": {
      fontSize: "10px",
      marginBottom: "10px",
      marginLeft: "1em",
      marginRight: "1em",
    },
    ".text-contact-soon": {
      textAlign: "center",
      fontSize: "1em",
      marginBottom: "0.5em",
    },
    ".terms-and-conditions": {
      fontSize: "10px",
      lineHeight: "15px",
    },
    ".check-label": {
      fontSize: "12px",
      lineHeight: "16px",
    },
    "otp-disclaimer-text": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#666666",
    },
    ".input-box": {
      width: "100%",
    },
    ".select-box": {
      width: "100%",
    },
  },
}));

const CarouselDrawer = () => {
  const state = useContext(Context);
  const { form, loading } = useCarouselDrawer();

  let open = state.showCarouselDrawerpopup;
  const { hideCarouselpopup } = useContext(Context);
  const [isCheckedModal, setIsCheckedModal] = useState(true);

  const handleCheckboxChangeModal = () => {
    let payload = {
      type: state?.carouselDrawerTrigger,
      checkBoxValue: !isCheckedModal,
    };
    sendAnalytics("v2_agree_clicked", payload, "en");
    setIsCheckedModal(!isCheckedModal);
  };
  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  useEffect(() => {
    form.initialValues.mobile = cacheInfo?.phone;
  }, [cacheInfo, form.initialValues]);
  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          let payload = {
            type: state?.carouselDrawerTrigger,
          };
          sendAnalytics("v2_lead_form_closed", payload, "en");
          hideCarouselpopup();
        }}
        onOpen={open}
        transitionDuration={1000}
        style={{
          display: { xs: "block", sm: "none" },
        }}
        sx={{
          "& .MuiDrawer-paper": {
            // position: "relative",
            overflowY: "visible",
          },
        }}
        PaperProps={{
          sx: {
            // backgroundColor: "unset",
            // backgroundSize: "100% 20%",
            // color: "red",
            // background: `linear-gradient(to bottom, red 0, red 100%) no-repeat`,
            // backgroundSize: `calc(100%) calc(100% - 400px)`,
          },
        }}
      >
        <Box
          onClick={() => {
            let payload = {
              type: state?.carouselDrawerTrigger,
            };
            sendAnalytics("v2_lead_form_closed", payload, "en");
            hideCarouselpopup();
          }}
          sx={{
            fontSize: "20px",
            color: "grey",
            position: "absolute",
            right: "4px",
            top: "4px",
          }}
        >
          <CancelIcon />
        </Box>
        <Box>
          <Avatar
            alt="test"
            src={imagesList.money_icon}
            style={{
              position: "absolute",
              padding: "8px",
              top: -70,
              right: "30%",
              width: 150,
              height: 150,
            }}
          />
        </Box>

        <CustomCarouselDrawer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
              Save more with Turno Electric Vehicle
            </Typography>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography style={{ fontSize: "12px", fontWeight: "400" }}>
              On all Electric Vehicles
            </Typography>
          </Box> */}

          <Box>
            <form onSubmit={form.handleSubmit} className="form-schedule">
              <Box sx={{ mb: "1em", mt: "1em" }}>
                <MemoizedOTP
                  checked={isCheckedModal}
                  input_type="whatsapp"
                  form={form}
                  className="otp-styles"
                  connect_type={state.connect_type}
                  type=""
                />
              </Box>
              <Box sx={{ mb: "1em" }}>
                <MemoizedSelectDropDown
                  form={form}
                  id={"state"}
                  name="state"
                  required={true}
                  title={"Select State"}
                  value={form.values.state}
                  optionsData={StateOptions}
                  onChange={form.handleChange}
                  error={!!checkError("state", form)}
                  helperText={form.errors.state}
                />
              </Box>

              <Box>
                {!state.showOtpModule ? (
                  <Box
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "baseline",
                    }}
                    sx={{ mt: { md: "24px" } }}
                  >
                    <input
                      label="carousel-checkbox"
                      type="checkbox"
                      required={true}
                      checked={isCheckedModal}
                      onChange={handleCheckboxChangeModal}
                    />

                    <Typography className="check-label">
                      I agree to the terms and conditions below and give my
                      consent to receive calls and messages to provide further
                      information.
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography className="otp-disclaimer-text">
                      OTP Verification enables us to secure your account and
                      enrol you into our promotional programs
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className="button-lets-chat" sx={{ mb: "1em", mt: "1em" }}>
                <MemoizedButton
                  id="lead-form"
                  disabled={!isCheckedModal}
                  type="submit"
                  content={"Talk to our Expert"}
                  sx={{
                    alignItems: "center",
                    background: primary.main,
                    width: "100%",
                    border: "0px",
                    borderRadius: "4px",
                  }}
                  loading={loading}
                  handleClick={form.handleSubmit}
                />
              </Box>
              <Typography
                className="terms-and-conditions"
                onClick={() => {
                  myScroll(
                    0,
                    document.getElementById("footer").offsetTop - 100,
                    "smooth"
                  );
                  // window?.scrollTo &&
                  //   window?.scrollTo({
                  //     top: document.getElementById("footer").offsetTop - 100,
                  //     behavior: "smooth",
                  //   });
                }}
              >
                Terms and Conditions
              </Typography>
            </form>
          </Box>
        </CustomCarouselDrawer>
      </Drawer>
    </Box>
  );
};

export default CarouselDrawer;
