import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

import { offer_id, offer_name, tag_id, tag_name } from "../../../constants";
import { mapStates } from "../../../constants/mapRegions";
import { vehicleInfo, vehicles } from "../../../constants/vehicleData";
import { Context } from "../../../context/context";
import LeadService from "../../../services/leadService";
import { validLeadConvert } from "../../../services/leadValidation";
import sessionTracking from "../../../services/sessionTracking";
import { userQlFlow } from "../../../services/userQLFlow";
import { postWhatsAppTrack } from "../../../services/whatsAppTracking";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { getCookie } from "../../../utils/cookie";

const useBookVehicleDrawer = () => {
  const state = useContext(Context);
  const [vehicleData, setVehicleData] = useState([]);

  const {
    showSnackbar,
    hideFormPopup,
    changeState,
    changeVehicleUuid,
    updateUserQlData,
    showReviewStoryDrawer,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  let location = useRouter();
  const brand = state?.vehicleBrand;
  let vehicle_id = 4;
  if (brand === "OSM") vehicle_id = 3;
  else if (brand === "piaggio") vehicle_id = 2;
  else if (brand === "mahindra") vehicle_id = 1;

  useEffect(() => {
    if (state?.vehicleBrand) {
      let vehicleDataLocal = vehicles.filter(function (vehicle) {
        return vehicle.id === vehicleInfo[state?.vehicleBrand]?.vehicleId;
      });
      changeVehicleUuid(vehicleDataLocal[0]?.uuid);
      setVehicleData(vehicleDataLocal[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.vehicleBrand]);
  const leadIntent =
    location.pathname === "/vehicles"
      ? "MEDIUM_INTENT"
      : location.pathname === "/"
        ? "LOW_INTENT"
        : "HIGH_INTENT";

  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  const form = useFormik({
    validateOnChange: true,
    initialValues: {
      state: "none",
      mobile: cacheInfo?.phone ?? "",
      otp: "",
      name: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values?.mobile?.toString()) {
        errors.mobile = "Mobile Number is Mandatory";
      }
      if (values?.mobile?.toString()?.length !== 10) {
        errors.mobile = "Enter a valid 10 digit Mobile Number";
      }
      if (values?.mobile && values?.mobile[0] < 5) {
        errors.mobile = "Enter Valid Mobile Number";
      }
      if (values?.state === "none") {
        errors.state = "Select State";
      }
      if (state?.showOtpModule) {
        if (!values?.otp?.toString()) {
          errors.otp = "Otp Number is Mandatory";
        }
        if (values?.otp?.toString()?.length !== 6) {
          errors.otp = "Enter Valid Otp";
        }
      }

      let lead_trigger = getCookie("lead_trigger")
        ? JSON.parse(getCookie("lead_trigger"))
        : "";
      const type =
        state?.serviceType === "Book Vehicle"
          ? "book_vehicle_tile"
          : "test_drive";

      const ipInformation = localStorage.getItem("geoLocation")
        ? JSON.parse(localStorage.getItem("geoLocation"))
        : {};

      if (values?.mobile?.toString()?.length === 10) {
        let payload = {
          phoneNumber: values?.mobile,
          connectType: "book vehicle card",
          type: lead_trigger === "tco" ? "TCO" : type,
          cityInfo: ipInformation?.city,
          geoInfo: ipInformation,
        };
        sendAnalytics("v2_lead_form_entered_mobile", payload, "en");

        const leadPayload = {
          name: "",
          phone: values?.mobile,
          state: mapStates[ipInformation?.countryRegion] ?? "Others",
          leadIntent: leadIntent,
          subSource: "book vehicle card",
        };

        // param: leadpayload, analyticsPayload
        validLeadConvert(leadPayload, payload);
      }

      return errors;
    },

    onSubmit: (values) => {
      changeState(values.state);
      const payload = {
        name: values?.name,
        phone: values?.mobile,
        pan: "",
        pincode: "",
        dob: "",
        state: values?.state,
        optedExchange: "",
        vehicleSelected: brand,
        language: "",
        refNumber: "",
        leadIntent: leadIntent,
        subSource: "book vehicle card",
        verified: state?.leadVerifiedStatus,
      };

      let lead_trigger = getCookie("lead_trigger")
        ? JSON.parse(getCookie("lead_trigger"))
        : "";

      setLoading(true);
      const type =
        state?.serviceType === "Book Vehicle"
          ? "book_vehicle_tile"
          : "test_drive";
      const analyticsPayload = {
        vehicle_name: brand,
        vehicle_id: vehicle_id,
        price_shown: vehicleData?.price,
        emi_shown: vehicleData?.emiPrice,
        tag_id: tag_id,
        tag_name: tag_name,
        offer_id: offer_id,
        offer_name: offer_name,
        phone_number: values?.mobile,
        name: values?.name,
        state: values?.state,
        button_trigger_location: "option_tile",
        type: lead_trigger === "tco" ? "TCO" : type,
        lead_trigger: lead_trigger,
      };

      sendAnalytics("reserve_vehicle_button_clicked", analyticsPayload, "en");
      LeadService(payload)
        .then((response) => {
          const sessionInfo = {
            leadUuid: response?.data?.uuid,
            newSession: true,
            formSubmitted: true,
            leadIntent: "MEDIUM_INTENT",
          };
          let bookingInfo = {
            leadUuid: response?.data?.uuid,
            vehicleUuid: state.vehicleUuid,
          };

          sessionTracking(sessionInfo);
          setLoading(false);

          showSnackbar("We will get back to you soon!!", "success");

          postWhatsAppTrack(payload);
          // if (state?.serviceType === "Book Vehicle") {
          //   PaymentService(bookingInfo)
          //     .then((response) => {
          //       localStorage.setItem(
          //         "formData",
          //         JSON.stringify({
          //           name: values?.name,
          //           phone: values?.mobile,
          //           state: values?.state,
          //           bookingId: response?.data?.vehicleBooking?.uuid,
          //           vehicle: state?.brand,
          //         })
          //       );

          //       if (response?.data?.paymentSessionId) {
          //         const Cashfree = window.Cashfree;
          //         const sessionId = response?.data?.paymentSessionId;
          //         const cf = new Cashfree(sessionId);
          //         cf.redirect();

          //         showSnackbar(
          //           "Let's pay and get the vehicle in just 5 days",
          //           "success"
          //         );
          //       } else {
          //         showSnackbar(
          //           "Oops, Something went wrong Try Again!!",
          //           "error"
          //         );
          //       }
          //       setLoading(false);
          //     })
          //     .catch((error) => {
          //       showSnackbar(
          //         "Oops, something went wrong. Please try again later.",
          //         "error"
          //       );
          //     });
          // }

          showSnackbar("We will get back to you soon!!", "success");
          setLoading(false);
          hideFormPopup();

          // Enable QL Lead flow
          userQlFlow(state, updateUserQlData, showReviewStoryDrawer);
        })
        .catch((error) => {
          showSnackbar(
            "Oops, something went wrong. Please try again later.",
            "error"
          );
        });
    },
  });

  return {
    form,
    loading,
  };
};

export default useBookVehicleDrawer;
