import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SwiperCore,{ Pagination, Autoplay} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { primary } from "../../../constants/theme";
import { newVehicles, vehicles } from "../../../constants/vehicleData";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { useMobile } from "../../../utils/findViewport";
import ModelCard from "./modelCard";
import { imagesWEBP } from "../../../constants/webpImages";
import NewLaunchModelCard from "./newLaunchModel";
const CustomLatestModel = styled(Box)(({ theme }) => ({
  //   background: "#FFF9FA",
  justifyContent: "center",
  // margin: "10px",
  ".main-box": {
    margin: "10px",
    marginBottom: "20px",
  },
  ".head-text": {
    padding: "0px 10px",
    fontWeight: 600,
    fontSize: "27px",
    marginTop: "30px",
    paddingLeft: "16px",
  },
  ".sub-text": {
    fontSize: "12px",
    color: "#9C9A9D",
    fontWeight: "600",
    padding: "0px 10px",
    paddingLeft: "16px",
  },
  ".image-box": {
    width: "100%",
    padding: "20px 0px",
  },
  ".test-drive-image": {
    width: "100%",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  ".MuiOutlinedInput-root": {
    paddingRight: "2px !important",
  },
  ".termsconditions": {
    fontSize: "10px",
  },
  ".vehicle-image-box": {
    // width: "100%",
    // minHeight: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px 5px",
  },
  ".vehicle-image": {
    // maxHeight: "180px",
    width: "100%",
  },
  //   ".form-box": {
  //     width: "100%",
  //   },
}));

const NewLaunchSection = ({
  headerInfo,
  view,
  isHeader = true,
  hideButton = false,
  vehicleData,
}) => {
  let allVehicles = [...vehicles, ...newVehicles];
  let passengerVehicles = allVehicles.filter(vehicle => vehicle.type === "passenger" );
  let cargoVehicle4w = allVehicles.filter(vehicle => vehicle.type === "cargo4wheeler" && vehicle.vehicle_path_id==='K1.5'); 
  let otherVehicles = allVehicles.filter(vehicle => vehicle.vehicle_path_id==='Zor-Grand' || vehicle.vehicle_path_id==='E-Xtra-FX-Max');
  passengerVehicles = passengerVehicles.slice(0, 2);
  // cargoVehicle4w = cargoVehicle4w.slice(0, -1);
  let vehicleDataList = [...passengerVehicles,...otherVehicles,...cargoVehicle4w];

  const targetRef = useRef(null);
  const [count, setCount] = useState(0);
  const mobile = useMobile();
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_latest_model", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);

  SwiperCore.use([Autoplay]);

  return (
    <CustomLatestModel>
      <Box className="main-box" id="latest-model" ref={targetRef}>
        <Box className="vehicle-image-box">
          <h2
            style={{
              fontWeight: "700",
              fontFamily: "'Poppins', sans-serif !important",
              lineHeight: "1.5",
              fontSize: "27px",
              whiteSpace: "wrap",
              margin: "0px 0px"
            }}
          >
           Electric Vehicles @{" "}
            <span style={{ color: "red" }}>turno</span>
          </h2>
        </Box>

        <Box>
          <Swiper
            slidesPerView={mobile ? view ?? 1 : 4.2}
            // centeredSlides={false}
            pagination={false}
            loop={true}
            modules={[Pagination]}
            autoplay={{
              delay: 1200,
              disableOnInteraction: false,
            }}

            className="swiper-turno-india"
            // onSlideChange={() => {
            //   let newCount = count + 1;
            //   setCount(newCount);
            //   if (newCount > 1)
            //     sendAnalytics("v2_latest_model_slide", {}, "en");
            // }}
          >
            {(vehicleData ?? vehicleDataList)?.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <NewLaunchModelCard data={data} hideButton={hideButton} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* <Typography
            style={{
              fontSize: "14px",
              // fontWeight: "700",
              lineHeight: "30px",

              margin: "10px",
            }}
          >
            Powered by{" "}
            <span style={{ color: primary?.main }}>
              <LazyLoadImage
                style={{ maxHeight: "14px" }}
                src={"/logoIcon.png"}
                visibleByDefault={true}
                alg="turnologo"
              />
            </span>
          </Typography> */}
        </Box>
      </Box>
    </CustomLatestModel>
  );
};

export default NewLaunchSection;
