import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Typography, styled } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "swiper/css";
import "swiper/css/pagination";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import { sendAnalytics } from "../../../utils/analyticsCommon";

const CustomVideoCallSale = styled(Box)(({ theme }) => ({
  margin: "20px 10px 20px 10px",

  ".main-box": {
    margin: "10px",
    marginBottom: "20px",
  },
  ".head-text": {
    padding: "0px 10px",
    fontWeight: 600,
    fontSize: "27px",
    marginTop: "30px",
    paddingLeft: "16px",
  },
  ".sub-text": {
    fontSize: "12px",
    color: "#9C9A9D",
    fontWeight: "600",
    padding: "0px 10px",
    paddingLeft: "16px",
  },
  ".image-box": {
    width: "100%",
    padding: "20px 0px",
  },
  ".test-drive-image": {
    width: "100%",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  ".MuiOutlinedInput-root": {
    paddingRight: "2px !important",
  },
  ".termsconditions": {
    fontSize: "10px",
  },
}));

const VideoCallSale = ({ data }) => {
  //   cons;
  //const navigate = useRouter();

  const { showLoanPopup } = useContext(Context);
  const targetRef = useRef(null);
  const navigate = useRouter();
  const brandName = data?.brand;

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_video_call_scroll", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);

  return (
    <CustomVideoCallSale>
      <Box
        id="video-call"
        ref={targetRef}
        onClick={() => {
          const payload = {};

          sendAnalytics("v2_video_call_clicked", payload, "en");

          const loanPayload = {
            brand: "",
            formTrigger: "v2_video_call_clicked",
            CTAText: "Connect",
            headingText: "Enter Your Mobile Number",
          };

          showLoanPopup(loanPayload);

          //navigate.push(`/vehicles/${data?.brand}`);
        }}
      >
        <Box>
          <Typography className="head-text">{"Shop Online"}</Typography>
        </Box>
        <Box>
          <Typography className="sub-text">BY TURNO</Typography>
        </Box>
        <LazyLoadImage
          src={imagesWEBP?.videoCall}
          style={{
            width: "100%",
            minHeight: "180px",
            borderRadius: "8px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          alt="video call"
        />

        <Box>
          <MemoizedButton
            //animated={true}
            content={"Connect on WhatsApp Video Call"}
            startIcon={<WhatsAppIcon />}
            sx={{
              //background: "#35BF68",
              width: "100%",
              background: `linear-gradient(90deg, #179F29 5.21%, #4ACC5B 68.23%)`,
              borderRadius: "4px",
              padding: "12px 8px",
              fontSize: "16px",
              color: "white",
              boxShadow: "0px 4px 4px  #4ACC5B",

              "&:hover": {
                background: primary.mainGreen,
                color: "white",
              },
            }}
            handleClick={() => {
              sendAnalytics("v2_video_call_button_clicked", {}, "en");
            }}
          />
        </Box>
      </Box>
    </CustomVideoCallSale>
  );
};

export default VideoCallSale;
