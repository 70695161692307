import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Link, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { VehicleBrochure } from "../../../constants/vehicleData";
import { Context } from "../../../context/context";
import { sendAnalytics } from "../../../utils/analyticsCommon";
// import useVehicleSlider from "./hooks/useVehicleSlider";
// import MemoizedPhoneField from "../../../SDK/input/phoneInput";
// import { checkError } from "../../../SDK/utils/validationHelpers";
// import { MemoizedButton } from "../../../SDK/button";
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "100%",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "50vh",
  },
  marginBottom: "30px",
}));

const CustomVehicleSlideDrawer = styled(Box)(({ theme }) => ({
  ".slider-box": {
    margin: "60px 0px 00px 0px",
    width: "100%",
    borderRadius: "10px",
  },

  ".image-gallery-bullet": {
    backgroundColor: "grey !important",
    boxShadow: "none !important",
  },
  ".image-gallery-bullet.active": {
    backgroundColor: "black !important",
  },
  ".book-test-drive-button": {
    borderRadius: "8px !important",
    boxShadow: "none !important",
    border: "none !important",
    paddingLeft: "10px !important",
    paddingRight: "10px !important",
    "&:hover": {
      background: "#F63B63",
      border: "none",
      color: "white",
      boxShadow: "none",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  ".MuiOutlinedInput-root": {
    paddingRight: "2px !important",
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: "20px",
  },
}));

const FullscreenDrawer = ({}) => {
  const state = useContext(Context);
  const { hideVehicleListDrawer, showVehicleListDrawer } = useContext(Context);
  // const { form, loading } = useVehicleSlider();
  const data = state.showVehicleListValue;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => (event) => {
    if (isOpen === false) {
      let payload = {
        vehicle_name: data?.name,
        vehicle_id: data?.id,
      };
      sendAnalytics("v2_view_vehicle_image_drawer_close", payload, "en");
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (state.showVehicleList) {
      hideVehicleListDrawer();
    } else {
      showVehicleListDrawer();
    }

    setOpen(isOpen);
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    let imagesArray = [];
    if (data?.galleryViewImages) {
      data?.galleryViewImages?.map((image) => {
        imagesArray.push({
          original: image?.src,
        });
      });
    } else {
      imagesArray.push({ original: data?.storeImage });
    }
    setImages(imagesArray);
  }, [data]);

  return (
    <div>
      {/* <Button onClick={toggleDrawer(true)}>Open Drawer</Button> */}
      <Drawer
        className={classes.drawer}
        anchor="bottom"
        open={state.showVehicleList}
        onClose={toggleDrawer(false)}
      >
        <CustomVehicleSlideDrawer>
          <Box>
            <div className={classes.content}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  style={{
                    background: "#FEEBEF",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                >
                  <CloseIcon onClick={toggleDrawer(false)} />
                </Box>
                <Box>
                  { data?.vehicle_name ==='Storm EV' ? null :
                  <Link
                    id="performance-range"
                    // ref={targetRef}
                    target="_blank"
                    href={
                      data?.brochure_url
                        ? data?.brochure_url
                        : VehicleBrochure[data?.brand?.toLowerCase()]
                    }
                    hrefLang={"en"}
                    rel="noreferrer"
                    underline="none"
                    onClick={() => {
                      let payload = {
                        vehicle_name: data?.name,
                        vehicle_id: data?.id,
                      };
                      sendAnalytics(
                        "v2_vehicle_brochure_download_clicked",
                        payload,
                        "en"
                      );
                    }}
                  >
                    <Box
                      style={{
                        background: "#FEEBEF",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        padding: "5px",
                      }}
                    >
                      Download Brochure
                      <DownloadIcon sx={{ color: "#F63B63" }} />
                    </Box>
                  </Link>
}
                </Box>
              </Box>

              <Box className="slider-box">
                <ReactImageGallery
                  autoPlay={false}
                  showNav={true}
                  showBullets={true}
                  style={{ height: "2px" }}
                  items={images}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  onSlide={() => {
                    let payload = {
                      vehicle_name: data?.name,
                      vehicle_id: data?.id,
                    };
                    sendAnalytics(
                      "v2_vehicle_image_slide_button_clicked",
                      payload,
                      "en"
                    );
                  }}
                  //loading={"lazy"}
                  // slideDuration={4000}
                  // swipingTransitionDuration={1000}
                />
              </Box>

              {/* <Box>
                <form onSubmit={form.handleSubmit}>
                  <Box className="input-box">
                    <MemoizedPhoneField
                      sx={{ minWidth: "100% !important", paddingRight: "0px" }}
                      error={!!checkError("mobile", form)}
                      helperText={form.errors.mobile}
                      className="input-field"
                      name="mobile"
                      placeholder="Your Mobile"
                      value={form.values.mobile}
                      onChange={(e) => {
                        form.handleChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+91</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <MemoizedButton
                              className="book-test-drive-button"
                              type="submit"
                              content={"Get Quote"}
                              loading={loading}
                              handleClick={(e) => {
                                form.handleSubmit(e);
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </form>
              </Box> */}
            </div>
          </Box>
        </CustomVehicleSlideDrawer>
      </Drawer>
    </div>
  );
};

export default FullscreenDrawer;
