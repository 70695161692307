import React from 'react'
import { Box, Card, styled, Typography } from "@mui/material";
import  Image  from 'next/image';
import { imagesWEBP } from '../../../constants/webpImages';
import Style from './Categories.module.scss';
const categoriesList =[
    {
        text: "3 wheeler passenger",
        image: imagesWEBP?.passengerLogo,
        redirect: "/electric-passenger-vehicles",
        selected:true
    },
    {
        text: "3 wheeler cargo",
        image: imagesWEBP?.cargoLogo,
        redirect: "/electric-commercial-vehicles",
        selected:false
    },
    {
        text: "4 wheeler cargo",
        image: imagesWEBP?.cargoLogo4w,
        redirect: "/electric-commercial-vehicles/4w",
        selected:false
    }
]
export const Categories = () => {
  return (
    <Box
    className={Style.mainSection}
    >
        <Box 
        className={Style.sectionHeading}
        >
        Check Out Our Vehicles
        </Box>
        <Box 
        className={Style.categories}
        >
                {categoriesList.map((category, index) => (
                       <Box key={index}
                       className={
                            Style.category
                       }
                     
                       >
                        <Box
                        className={`${Style.categoryImageContainer}  ${category.selected && Style.selectedCategory}`}
                        onClick={() => {
                            window.location.href
                            = category.redirect;
                            }
                        }
                        >
                            <img
                            src={category.image}
                            // width={107}
                            // height={75}
                            alt={category.text}
                            className={Style.categoryImage}
                            />
                        </Box>
                            <Box
                            className={Style.categoryTextContainer}
                            >
                                <div
                                className={Style.categoryText}
                                >
                                {category.text}
                                </div>
                               
                            </Box>
                        </Box>
                ))}
        </Box>
    </Box>
  )
}
