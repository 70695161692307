import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useContext, useState } from "react";

import { offer_id, offer_name, tag_id, tag_name } from "../../../constants";
import { mapStates } from "../../../constants/mapRegions";
import { Context } from "../../../context/context";
import LeadService from "../../../services/leadService";
import { validLeadConvert } from "../../../services/leadValidation";
import sessionTracking from "../../../services/sessionTracking";
import { userQlFlow } from "../../../services/userQLFlow";
import { postWhatsAppTrack } from "../../../services/whatsAppTracking";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { createCookieInHour, getCookie } from "../../../utils/cookie";

const useCarouselDrawer = () => {
  const state = useContext(Context);

  const {
    showSnackbar,
    hideFormPopup,
    changeState,
    hideCarouselpopup,
    updateUserQlData,
    showReviewStoryDrawer,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  let location = useRouter();
  const brand = state?.vehicleBrand;

  const leadIntent =
    location.pathname === "/vehicles"
      ? "MEDIUM_INTENT"
      : location.pathname === "/"
        ? "LOW_INTENT"
        : "HIGH_INTENT";

  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  const form = useFormik({
    validateOnChange: true,
    initialValues: {
      state: "none",
      mobile: cacheInfo?.phone ?? "",
      otp: "",
      name: "",
    },

    validate: (values) => {
      const errors = {};

      const ipInformation = localStorage.getItem("geoLocation")
        ? JSON.parse(localStorage.getItem("geoLocation"))
        : {};

      if (values?.mobile?.toString()?.length === 10) {
        let payload = {
          type: state?.carouselDrawerTrigger,
          phone_number: values?.mobile,
          cityInfo: ipInformation?.city,
          geoInfo: ipInformation,
        };
        sendAnalytics("v2_lead_form_entered_mobile", payload, "en");

        const leadPayload = {
          name: "",
          phone: values?.mobile,
          state: mapStates[ipInformation?.countryRegion] ?? "Others",
          leadIntent: leadIntent,
          subSource: "Whatsapp popup",
        };

        // param: leadpayload, analyticsPayload
        validLeadConvert(leadPayload, payload);
      }
      if (values?.state !== "none") {
        let payload = {
          type: state?.carouselDrawerTrigger,
          phone_number: values?.state,
        };
        sendAnalytics("v2_lead_form_selected_state", payload, "en");
      }

      if (!values?.mobile?.toString()) {
        errors.mobile = "Mobile Number is Mandatory";
      }
      if (values?.mobile?.toString()?.length !== 10) {
        errors.mobile = "Enter a valid 10 digit Mobile Number";
      }
      if (values?.mobile && values?.mobile[0] < 5) {
        errors.mobile = "Enter Valid Mobile Number";
      }
      if (values?.state === "none") {
        errors.state = "Select State";
      }
      if (state?.showOtpModule) {
        if (!values?.otp?.toString()) {
          errors.otp = "Otp Number is Mandatory";
        }
        if (values?.otp?.toString()?.length !== 6) {
          errors.otp = "Enter Valid Otp";
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      createCookieInHour("lead_trigger", "carousel_drawer", 10);

      changeState(values.state);
      const payload = {
        name: values?.name,
        phone: values?.mobile,
        pan: "",
        pincode: "",
        dob: "",
        state: values?.state,
        optedExchange: "",
        vehicleSelected: brand,
        language: "",
        refNumber: "",
        leadIntent: leadIntent,
        subSource: "Whatsapp popup",
        verified: state?.leadVerifiedStatus,
      };

      setLoading(true);
      const analyticsPayload = {
        tag_id: tag_id,
        tag_name: tag_name,
        offer_id: offer_id,
        offer_name: offer_name,
        phone_number: values?.mobile,
        name: values?.name,
        state: values?.state,
        button_trigger_location: state?.carouselDrawerTrigger,
        type: "offer_carousel",
      };

      sendAnalytics("reserve_vehicle_button_clicked", analyticsPayload, "en");
      LeadService(payload)
        .then((response) => {
          const sessionInfo = {
            leadUuid: response?.data?.uuid,
            newSession: true,
            formSubmitted: true,
            leadIntent: "MEDIUM_INTENT",
          };

          sessionTracking(sessionInfo);
          postWhatsAppTrack(payload);
          hideCarouselpopup();

          showSnackbar("We will get back to you soon!!", "success");
          setLoading(false);

          // Enable QL Lead flow
          userQlFlow(state, updateUserQlData, showReviewStoryDrawer);
        })
        .catch((error) => {
          showSnackbar(
            "Oops, something went wrong. Please try again later.",
            "error"
          );
        });

      hideFormPopup();
    },
  });

  return {
    form,
    loading,
  };
};

export default useCarouselDrawer;
