import CancelIcon from "@mui/icons-material/Cancel";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Drawer,
  Rating,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { MemoizedButton } from "../../../SDK/button";
import { MemoizedNameField } from "../../../SDK/input/nameInput";
import { MemoizedSelectDropDown } from "../../../SDK/selectDropdown/selectDropdown";
import { checkError } from "../../../SDK/utils/validationHelpers";
import { StateOptions, imagesList } from "../../../constants";
import { primary } from "../../../constants/theme";
import { vehicleInfo, vehicles } from "../../../constants/vehicleData";
import { Context } from "../../../context/context";
import { getCookie } from "../../../utils/cookie";
import { useMobile } from "../../../utils/findViewport";
import { myScroll } from "../../../utils/myScroll";
import { MemoizedOTP } from "../../Otp/otp";
import useBookVehicleDrawer from "./useBookVehicleDrawer";

const CustomBookVehicleDrawer = styled(Box)(({ theme }) => ({
  marginTop: "0px",
  borderRadius: "18px",
  // marginBottom: "40px",
  padding: "0.8em",
  "input-field": {
    minWidth: "100%",
    width: "100% !important",
  },

  ".input-box": {
    //marginBottom: "10px",
    //marginTop: "20px",

    width: "100%",
  },
  ".select-box": {
    margin: "10px",
    marginTop: "20px",
    width: "96%",
  },
  ".button-lets-chat": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".heading": {
    fontWeight: "700",
    textAlign: "center",
    fontSize: "2em",
  },
  ".text-contact-soon": {
    textAlign: "center",
    fontSize: "1.5em",
    marginBottom: "0.5em",
  },
  ".receive-notifications": {
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "3em",
    marginRight: "3em",
  },
  ".terms-and-conditions": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    maxWidth: "542px",
    color: primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  ".check-label": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "400",
    fontSize: "12px",
  },
  ".otp-disclaimer-text": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "700",
    color: "#666666",
    fontSize: "12px",
  },

  ".desktop-view": {
    display: "block",
  },

  ".mobile-view": {
    display: "none",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "40px",
    ".desktop-view": {
      display: "none",
    },
    ".mobile-view": {
      display: "block",
    },
    ".heading": {
      fontWeight: "700",
      textAlign: "center",
      fontSize: "1em",
    },
    ".receive-notifications": {
      fontSize: "10px",
      marginBottom: "10px",
      marginLeft: "1em",
      marginRight: "1em",
    },
    ".text-contact-soon": {
      textAlign: "center",
      fontSize: "1em",
      marginBottom: "0.5em",
    },
    ".terms-and-conditions": {
      fontSize: "10px",
      lineHeight: "15px",
    },
    ".check-label": {
      fontSize: "12px",
      lineHeight: "16px",
    },
    "otp-disclaimer-text": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#666666",
    },
    ".input-box": {
      width: "100%",
    },
    ".select-box": {
      width: "100%",
    },
  },
}));

const BookVehicleDrawer = () => {
  const state = useContext(Context);
  const { form, loading } = useBookVehicleDrawer();

  let open = state.showFormDrawerPopup;
  const { hideFormPopup } = useContext(Context);
  const [isCheckedModal, setIsCheckedModal] = useState(true);
  const [vehicleData, setVehicleData] = useState([]);

  const mobile = useMobile();

  const handleCheckboxChangeModal = () => {
    setIsCheckedModal(!isCheckedModal);
  };
  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  useEffect(() => {
    if (state?.vehicleBrand) {
      let vehicleDataLocal = vehicles.filter(function (vehicle) {
        return vehicle.id === vehicleInfo[state?.vehicleBrand]?.vehicleId;
      });
      setVehicleData(vehicleDataLocal[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  useEffect(() => {
    form.initialValues.mobile = cacheInfo?.phone;
  }, [cacheInfo, form.initialValues]);

  const deskTopView = () => {
    return (
      <Box>
        <Dialog
          open={open}
          onClose={() => {
            hideFormPopup();
            // sendAnalytics("enquiry_ev_consult_popup_dismissed", {}, "en");
          }}
          PaperProps={{
            sx: { display: { xs: "none", md: "block" } },
          }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <Box
              onClick={() => {
                hideFormPopup();
              }}
              style={{
                fontSize: "20px",
                color: "grey",
                position: "absolute",
                right: "4px",
                top: "4px",
              }}
            >
              <CancelIcon fontSize="large" />
            </Box>

            <CustomBookVehicleDrawer>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  alt="test"
                  src={imagesList[vehicleData?.brand]}
                  style={{
                    // position: "absolute",
                    // top: -70,
                    // right: "30%",
                    width: 150,
                    height: 150,
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Rating
                  name="half-rating"
                  defaultValue={vehicleData?.rating}
                  precision={0.1}
                />
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Typography style={{ fontSize: "11px" }}>
                  {vehicleData?.rating}/5
                </Typography>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <LazyLoadImage
                  src={vehicleData?.vehicle_logo}
                  height="35px"
                  alt={vehicleData?.brand}
                />
                <Typography style={{ padding: "5px 20px", fontSize: "14px" }}>
                  {vehicleData?.name}
                </Typography>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    padding: "10px",
                  }}
                >
                  {state?.serviceType === "Book Vehicle"
                    ? "Book your Vehicle"
                    : "Book your Test Drive"}
                </Typography>
                {/* {state?.serviceType === "Book Vehicle" && (
                  <Box>
                    {" "}
                    <LazyLoadImage
                      src={imagesList.bookingOfferApplied}
                      width="150px"
                      height="40px"
                      alt="Offer Applied"
                    />
                  </Box>
                )} */}
              </Box>

              <Box>
                <form onSubmit={form.handleSubmit} className="form-schedule">
                  <Box>
                    <MemoizedNameField
                      className="input-field"
                      name="name"
                      error={!!checkError("name", form)}
                      helperText={form.errors.name}
                      placeholder="Your Name"
                      value={form.values.name}
                      onChange={(e) => {
                        form.handleChange(e);
                      }}
                      style={{ width: "100%" }}
                    />
                  </Box>
                  <Box sx={{ mb: "1em", mt: "1em" }}>
                    <MemoizedOTP
                      checked={isCheckedModal}
                      input_type="whatsapp"
                      form={form}
                      className="otp-styles"
                      connect_type={state.connect_type}
                      type=""
                    />
                  </Box>
                  <Box sx={{ mb: "1em" }}>
                    <MemoizedSelectDropDown
                      form={form}
                      id={"state"}
                      name="state"
                      required={true}
                      title={"Select State"}
                      value={form.values.state}
                      optionsData={StateOptions}
                      onChange={form.handleChange}
                      error={!!checkError("state", form)}
                      helperText={form.errors.state}
                    />
                  </Box>

                  <Box>
                    {!state.showOtpModule ? (
                      <Box
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                        sx={{ mt: { md: "24px" } }}
                      >
                        <input
                          label="bookVehicle-checkbox"
                          type="checkbox"
                          required={true}
                          checked={isCheckedModal}
                          onChange={handleCheckboxChangeModal}
                        />

                        <Typography className="check-label">
                          I agree to the terms and conditions below and give my
                          consent to receive calls and messages to provide
                          further information.
                        </Typography>
                      </Box>
                    ) : (
                      <Box>
                        <Typography className="otp-disclaimer-text">
                          OTP Verification enables us to secure your account and
                          enrol you into our promotional programs
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    className="button-lets-chat"
                    style={{ marginBottom: "1em", marginTop: "1em" }}
                  >
                    <MemoizedButton
                      id="lead-form"
                      disabled={!isCheckedModal}
                      type="submit"
                      content={"Book test drive"}
                      style={{
                        alignItems: "center",
                        background: primary.main,
                        width: "100%",
                        border: "0px",
                        borderRadius: "8px",
                      }}
                      loading={loading}
                      handleClick={form.handleSubmit}
                    />
                  </Box>
                  <Typography
                    className="terms-and-conditions"
                    onClick={() => {
                      myScroll(
                        0,
                        document.getElementById("footer").offsetTop - 100,
                        "smooth"
                      );
                      // window?.scrollTo &&
                      //   window?.scrollTo({
                      //     top: document.getElementById("footer").offsetTop - 100,
                      //     behavior: "smooth",
                      //   });
                    }}
                  >
                    Terms and Conditions
                  </Typography>
                </form>
              </Box>
            </CustomBookVehicleDrawer>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  const mobileView = () => {
    return (
      <Box>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => {
            hideFormPopup();
          }}
          onOpen={open}
          transitionDuration={1000}
          // style={{

          // }}
          sx={{
            "& .MuiDrawer-paper": {
              // position: "relative",
              overflowY: "visible",
            },
          }}
          PaperProps={{
            sx: { display: { xs: "block", sm: "none" } },
          }}
        >
          <Box
            onClick={() => {
              hideFormPopup();
            }}
            style={{
              fontSize: "20px",
              color: "grey",
              position: "absolute",
              right: "4px",
              top: "4px",
            }}
          >
            <CancelIcon fontSize="large" />
          </Box>
          <Box>
            <Avatar
              alt="test"
              src={imagesList[vehicleData?.brand]}
              style={{
                position: "absolute",
                top: -70,
                right: "30%",
                width: 150,
                height: 150,
              }}
            />
          </Box>

          <CustomBookVehicleDrawer>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Rating
                name="half-rating"
                defaultValue={vehicleData?.rating}
                precision={0.1}
              />
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Typography style={{ fontSize: "11px" }}>
                {vehicleData?.rating}/5
              </Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <LazyLoadImage
                src={vehicleData?.vehicle_logo}
                height="35px"
                alt={vehicleData?.brand}
              />
              <Typography style={{ padding: "5px 20px", fontSize: "14px" }}>
                {vehicleData?.name}
              </Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{ fontWeight: "600", fontSize: "20px", padding: "10px" }}
              >
                {state?.serviceType === "Book Vehicle"
                  ? "Book your Vehicle"
                  : "Book your Test Drive"}
              </Typography>
              {/* {state?.serviceType === "Book Vehicle" && (
                <Box>
                  {" "}
                  <LazyLoadImage
                    src={imagesList.bookingOfferApplied}
                    width="150px"
                    height="40px"
                    alt="Offer Applied"
                  />
                </Box>
              )} */}
            </Box>

            <Box>
              <form onSubmit={form.handleSubmit} className="form-schedule">
                <Box>
                  <MemoizedNameField
                    className="input-field"
                    name="name"
                    error={!!checkError("name", form)}
                    helperText={form.errors.name}
                    placeholder="Your Name"
                    value={form.values.name}
                    onChange={(e) => {
                      form.handleChange(e);
                    }}
                    style={{ width: "100%" }}
                  />
                </Box>
                <Box sx={{ mb: "1em", mt: "1em" }}>
                  <MemoizedOTP
                    checked={isCheckedModal}
                    input_type="whatsapp"
                    form={form}
                    className="otp-styles"
                    connect_type={state.connect_type}
                    type=""
                  />
                </Box>
                <Box sx={{ mb: "1em" }}>
                  <MemoizedSelectDropDown
                    form={form}
                    id={"state"}
                    name="state"
                    required={true}
                    title={"Select State"}
                    value={form.values.state}
                    optionsData={StateOptions}
                    onChange={form.handleChange}
                    error={!!checkError("state", form)}
                    helperText={form.errors.state}
                  />
                </Box>

                <Box>
                  {!state.showOtpModule ? (
                    <Box
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                      sx={{ mt: { md: "24px" } }}
                    >
                      <input
                        label="bookVehicle-checkbox"
                        type="checkbox"
                        required={true}
                        checked={isCheckedModal}
                        onChange={handleCheckboxChangeModal}
                      />

                      <Typography className="check-label">
                        I agree to the terms and conditions below and give my
                        consent to receive calls and messages to provide further
                        information.
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography className="otp-disclaimer-text">
                        OTP Verification enables us to secure your account and
                        enrol you into our promotional programs
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  className="button-lets-chat"
                  style={{ marginBottom: "1em", marginTop: "1em" }}
                >
                  <MemoizedButton
                    id="lead-form"
                    disabled={!isCheckedModal}
                    type="submit"
                    content={"Book test drive"}
                    style={{
                      alignItems: "center",
                      background: primary.main,
                      width: "100%",
                      border: "0px",
                      borderRadius: "8px",
                    }}
                    loading={loading}
                    handleClick={form.handleSubmit}
                  />
                </Box>
                <Typography
                  className="terms-and-conditions"
                  onClick={() => {
                    myScroll(
                      0,
                      document.getElementById("footer").offsetTop - 100,
                      "smooth"
                    );
                    // window?.scrollTo &&
                    //   window?.scrollTo({
                    //     top: document.getElementById("footer").offsetTop - 100,
                    //     behavior: "smooth",
                    //   });
                  }}
                >
                  Terms and Conditions
                </Typography>
              </form>
            </Box>
          </CustomBookVehicleDrawer>
        </Drawer>
      </Box>
    );
  };

  return <Box>{mobile ? mobileView() : deskTopView()}</Box>;
};

export default BookVehicleDrawer;
