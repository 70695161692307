import { Box, Grid, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef, useState } from "react";

import { StateOptions } from "../../../constants";
import { primary } from "../../../constants/theme";
import { Context } from "../../../context/context";
import { MemoizedButton } from "../../../SDK/button";
import { MemoizedSelectDropDown } from "../../../SDK/selectDropdown/selectDropdown";
import { checkError } from "../../../SDK/utils/validationHelpers";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { createCookieInHour, getCookie } from "../../../utils/cookie";
import { leadActions } from "../../../utils/leadActions";
import { MemoizedOTP } from "../../Otp/otp";
import UseBookEVConsultation from "./useBookEVConsultation";

const CustomBookEVConsultationForm = styled(Box)(({ theme }) => ({
  marginTop: "40px",
  marginBottom: "40px",
  margin: "12px",
  borderRadius: "8px",
  ".input-box": {
    width: "50%",
    background: "#ffffff !important",
  },
  ".select-box": {
    margin: "10px",
    marginTop: "20px",
    width: "96%",
    height: "10px",
  },
  ".button-lets-chat": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".heading": {
    fontWeight: "700",
    textAlign: "center",
    fontSize: "2em",
  },
  ".text-contact-soon": {
    textAlign: "center",
    fontSize: "1.5em",
    marginBottom: "0.5em",
  },
  ".receive-notifications": {
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "3em",
    marginRight: "3em",
  },
  ".terms-and-conditions": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    maxWidth: "542px",
    color: primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  ".check-label": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "400",
    fontSize: "12px",
    color: primary.greyText,
  },
  ".otp-disclaimer-text": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "700",
    color: "#666666",
    fontSize: "12px",
  },

  ".form-title": {
    fontSize: "17px",
    fontWeight: 900,
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  ".input-dropdown": {
    maxHeight: "56px",
    background: primary?.white,
  },
  [theme.breakpoints.down("md")]: {
    ".heading": {
      fontWeight: "700",
      textAlign: "center",
      fontSize: "24px",
    },
    ".receive-notifications": {
      fontSize: "10px",
      marginBottom: "10px",
      marginLeft: "1em",
      marginRight: "1em",
    },
    ".text-contact-soon": {
      textAlign: "center",
      fontSize: "1em",
      marginBottom: "0.5em",
    },
    ".terms-and-conditions": {
      fontSize: "10px",
      lineHeight: "15px",
    },
    ".check-label": {
      fontSize: "9px",
      //lineHeight: "16px",
    },
    "otp-disclaimer-text": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#666666",
    },
    ".input-box": {
      width: "100%",
    },
    ".select-box": {
      width: "100%",
    },
  },
}));
const BookEVConsultationForm = ({ fixState, ctaText, scrollName, sxCta }) => {
  const state = useContext(Context);
  const { form, loading } = UseBookEVConsultation(fixState);

  const [isCheckedModal, setIsCheckedModal] = useState(true);
  const navigate = useRouter();

  const handleCheckboxChangeModal = () => {
    setIsCheckedModal(!isCheckedModal);
  };
  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics(scrollName ?? "scroll_first_fold_form", "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef]);

  useEffect(() => {
    form.initialValues.mobile = cacheInfo?.phone;
  }, [cacheInfo, form.initialValues]);
  return (
    <CustomBookEVConsultationForm>
      <Box id="consult-form" ref={targetRef}>
        {/* <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "8px",
              fontSize: { lg: "24px", xs: "16px", md: "16px" },
            }}
          >
            <Typography className="form-title">
              Book your free
              <ElectricBolt
                sx={{
                  fontSize: { lg: "24px", xs: "16px", md: "16px" },

                  color: "#35BF68",
                }}
              />{" "}
              EV consultation
            </Typography>
          </Box>
        </Box> */}

        <form onSubmit={form.handleSubmit} className="form-schedule">
          <Box
            sx={{
              mb: "1em",
              mt: "1em",
              display: "flex",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MemoizedOTP
                  style={{ borderRadius: "8px", width: "100%" }}
                  checked={isCheckedModal}
                  input_type="whatsapp"
                  form={form}
                  className="otp-styles"
                  connect_type={state.connect_type}
                  type=""
                />
              </Grid>
              <Grid item xs={6}>
                <MemoizedSelectDropDown
                  className="input-dropdown"
                  // sx={{
                  //   borderRadius: "8px",

                  //   maxHeight: "56px",
                  // }}
                  form={form}
                  id={"state"}
                  name="state"
                  required={true}
                  title={"Select State"}
                  value={fixState ?? form?.values?.state}
                  optionsData={StateOptions}
                  onChange={form.handleChange}
                  error={!!checkError("state", form)}
                  helperText={form.errors.state}
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            {!state.showOtpModule ? (
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  //alignItems: "baseline",
                }}
                sx={{ mt: { xs: "12px", md: "24px" } }}
              >
                <input
                  type="checkbox"
                  label="whatsapp-checkbox"
                  style={{ width: "10px" }}
                  required={true}
                  checked={isCheckedModal}
                  onChange={handleCheckboxChangeModal}
                />

                <Typography className="check-label">
                  I agree to the terms and conditions below and give my consent
                  to receive calls and messages to provide further information.
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography className="otp-disclaimer-text">
                  OTP Verification enables us to secure your account and enrol
                  you into our promotional programs{" "}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className="button-lets-chat" sx={{ mb: "1em", mt: "1em" }}>
            <MemoizedButton
              id="lead-form"
              disabled={!isCheckedModal}
              type="submit"
              content={ctaText ?? "Talk to our expert"}
              loading={loading}
              style= {{
                background: primary.main
              }}
              sx={{
                width: "100%",
                background: primary.main,
                borderRadius: "50px",
                padding: "12px 8px",
                fontSize: "20px",
                color: "white",
                "&:hover": {
                  background: primary.main,
                  color: "white",
                },

                ...sxCta,
              }}
              handleClick={(e) => {
                leadActions("CLICK_EV_CONSULTATION");
                createCookieInHour("lead_trigger", "book_ev_consult_btn", 10);
                form.handleSubmit(e);
              }}
            />
          </Box>
        </form>
      </Box>
    </CustomBookEVConsultationForm>
  );
};

export default BookEVConsultationForm;
