import { Link, Typography } from "@material-ui/core";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Drawer, InputAdornment, styled } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { primary } from "../constants/theme";
import { Context } from "../context/context";
import { MemoizedButton } from "../SDK/button";
import { MemoizedPhoneField } from "../SDK/input/phoneInput";
import { checkError } from "../SDK/utils/validationHelpers";
import { sendAnalytics } from "../utils/analyticsCommon";
import { getCookie } from "../utils/cookie";
import useConnectDrawer from "./useConnectDrawer";

const CustomConnectDrawer = styled(Box)(({ theme }) => ({
  marginTop: "40px",
  marginBottom: "40px",
  padding: "1em",
  ".input-box": {
    width: "100%",
  },
  ".select-box": {
    margin: "10px",
    marginTop: "20px",
    width: "96%",
  },
  ".button-lets-chat": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".heading": {
    fontWeight: "700",
    textAlign: "center",
    fontSize: "2em",
  },
  ".text-contact-soon": {
    textAlign: "center",
    fontSize: "1.5em",
    marginBottom: "0.5em",
  },
  ".receive-notifications": {
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "3em",
    marginRight: "3em",
  },
  ".terms-and-conditions": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    maxWidth: "542px",
    color: primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  ".check-label": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "400",
    fontSize: "12px",
  },
  ".otp-disclaimer-text": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "700",
    color: "#666666",
    fontSize: "12px",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    margin: "0px",
    background: "#35BF68",
    "&:hover": {
      background: "#35BF68",
      border: "none",
      color: "white",
      boxShadow: "none",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  ".MuiOutlinedInput-root": {
    paddingRight: "2px !important",
    border: "1px solid #35BF68",
  },
  [theme.breakpoints.down("md")]: {
    ".heading": {
      fontWeight: "700",
      textAlign: "center",
      fontSize: "1em",
    },
    ".receive-notifications": {
      fontSize: "10px",
      marginBottom: "10px",
      marginLeft: "1em",
      marginRight: "1em",
    },
    ".text-contact-soon": {
      textAlign: "center",
      fontSize: "1em",
      marginBottom: "0.5em",
    },
    ".terms-and-conditions": {
      fontSize: "10px",
      lineHeight: "15px",
    },
    ".check-label": {
      fontSize: "12px",
      lineHeight: "16px",
    },
    "otp-disclaimer-text": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#666666",
    },
    ".input-box": {
      width: "100%",
    },
    ".select-box": {
      width: "100%",
    },
  },
}));

const ConnectDrawer = ({ heading, subHeading, ctaText }) => {
  const state = useContext(Context);
  const { form, loading } = useConnectDrawer();

  let open = state.showConnectDrawerValue;
  const { hideConnectDrawer } = useContext(Context);

  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  useEffect(() => {
    form.initialValues.mobile = cacheInfo?.phone;
  }, [cacheInfo, form.initialValues]);

  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          hideConnectDrawer();
        }}
        onOpen={open}
        transitionDuration={1000}
        sx={{ display: { xs: "block", sm: "none" } }}
      >
        <CustomConnectDrawer>
          <Box>
            <Box
              style={{
                fontWeight: "700",
                // textAlign: "center",
                paddingLeft: "10px",
                fontSize: "1em",
              }}
            >
              {/* Talk to Us */}
              {heading}
            </Box>
            <Box>
              <Typography
                style={{
                  fontSize: "14px",
                  paddingLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {subHeading}
                {/* We help you find the best vehicle for your business with
                affordable financing. Connect with us now. */}
              </Typography>
            </Box>
          </Box>

          <form onSubmit={form.handleSubmit} className="form-schedule">
            <Box sx={{ mb: "1em", mt: "2em" }}>
              <MemoizedPhoneField
                sx={{ minWidth: "100% !important", paddingRight: "0px" }}
                error={!!checkError("mobile", form)}
                helperText={form.errors.mobile}
                className="input-field"
                name="mobile"
                placeholder="Your Mobile"
                value={form.values.mobile}
                onChange={(e) => {
                  form.handleChange(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <MemoizedButton
                        className="book-test-drive-button"
                        type="submit"
                        content={"WhatsApp"}
                        loading={loading}
                        handleClick={(e) => {
                          // let payload = {
                          //   phoneNumber: form.values.mobile,
                          //   connectType: "floating_call_icon",
                          // };
                          // sendAnalytics(
                          //   "v2_whatsapp_chat_initiated",
                          //   payload,
                          //   "en"
                          // );
                          form.handleSubmit(e);
                        }}
                        endIcon={
                          <WhatsAppIcon
                            sx={{
                              color: "white",
                              marginRight: "2px",
                            }}
                          />
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </form>
          <Box className="button-lets-chat" sx={{ mb: "1em", mt: "1em" }}>
            <Link
              href="tel:08047482233"
              hrefLang={"en"}
              target="_blank"
              rel="noreferrer"
              underline="none"
              style={{ width: "100%" }}
              onClick={() => {
                let payload = {
                  connectType: "floating_call_icon",
                };
                sendAnalytics(
                  "v2_whatsapp_floating_call_button",
                  payload,
                  "en"
                );
                // leadActions("CLICK_WHATSAPP_CHAT");
                // createCookieInHour("lead_trigger", "Phone_Call_Initiated", 10);
                // sendAnalytics("phone_call_initiated", {}, "en");
              }}
            >
              <MemoizedButton
                id="lead-form"
                type="submit"
                content={ctaText}
                sx={{
                  alignItems: "center",
                  width: "100%",
                  border: "0px",
                  boxShadow: "none",
                  borderRadius: "8px",
                  background: "#EBF7EF",
                  color: "#35BF68",
                  "&:hover": {
                    background: "#EBF7EF",
                    color: "#35BF68",
                  },
                }}
                endIcon={
                  <PhoneInTalkIcon
                    sx={{
                      color: "#35BF68",
                      marginRight: "2px",
                    }}
                  />
                }
              />
            </Link>
          </Box>
        </CustomConnectDrawer>
      </Drawer>
    </Box>
  );
};

export const MemoizedConnectDrawer = React.memo(ConnectDrawer);
