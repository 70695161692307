import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { mapStates } from "../constants/mapRegions";

import { Context } from "../context/context";
import LeadService from "../services/leadService";
import { validLeadConvert } from "../services/leadValidation";
import sessionTracking from "../services/sessionTracking";
import { userQlFlow } from "../services/userQLFlow";
import { postWhatsAppTrack } from "../services/whatsAppTracking";
import { sendAnalytics } from "../utils/analyticsCommon";
import { getCookie } from "../utils/cookie";
import { whatsappNumber } from "../constants/vehicleData";

const useConnectDrawer = () => {
  const state = useContext(Context);

  const {
    showSnackbar,
    hideConnectDrawer,
    changeState,
    updateUserQlData,
    showReviewStoryDrawer,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  let location = useRouter();
  const brand = state?.vehicleBrand;
  let vehicle_id = 4;
  if (brand === "OSM") vehicle_id = 3;
  else if (brand === "piaggio") vehicle_id = 2;
  else if (brand === "mahindra") vehicle_id = 1;

  const leadIntent =
    location.pathname === "/vehicles"
      ? "MEDIUM_INTENT"
      : location.pathname === "/"
        ? "LOW_INTENT"
        : "HIGH_INTENT";

  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  const form = useFormik({
    validateOnChange: true,
    initialValues: {
      state: "none",
      mobile: cacheInfo?.phone ?? "",
      otp: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values?.mobile?.toString()) {
        errors.mobile = "Mobile Number is Mandatory";
      }
      if (values?.mobile?.toString()?.length !== 10) {
        errors.mobile = "Enter a valid 10 digit Mobile Number";
      }
      if (values?.mobile && values?.mobile[0] < 5) {
        errors.mobile = "Enter Valid Mobile Number";
      }

      // if (state?.showOtpModule) {
      //   if (!values?.otp?.toString()) {
      //     errors.otp = "Otp Number is Mandatory";
      //   }
      //   if (values?.otp?.toString()?.length !== 6) {
      //     errors.otp = "Enter Valid Otp";
      //   }
      // }

      const ipInformation = localStorage.getItem("geoLocation")
        ? JSON.parse(localStorage.getItem("geoLocation"))
        : {};

      if (values?.mobile?.toString()?.length === 10) {
        let payload = {
          phoneNumber: values?.mobile,
          connectType: state?.whatsAppConnectDrawerType,
          type: "whatsapp_chat_initiated",
          cityInfo: ipInformation?.city,
          geoInfo: ipInformation,
        };
        sendAnalytics("v2_lead_form_entered_mobile", payload, "en");

        const leadPayload = {
          name: "",
          phone: values?.mobile,
          state: mapStates[ipInformation?.countryRegion] ?? "Others",
          leadIntent: leadIntent,
          subSource: "Whatsapp popup",
        };

        // param: leadpayload, analyticsPayload
        validLeadConvert(leadPayload, payload);
      }

      return errors;
    },

    onSubmit: (values) => {
      changeState(values.state);
      const payload = {
        name: "",
        phone: values?.mobile,
        pan: "",
        pincode: "",
        dob: "",
        state: values?.state,
        optedExchange: "",
        vehicleSelected: brand,
        language: "",
        refNumber: "",
        leadIntent: leadIntent,
        subSource: "Whatsapp popup",
        verified: state?.leadVerifiedStatus,
      };

      setLoading(true);
      //   const analyticsPayload = {
      //     vehicle_name: brand,
      //     vehicle_id: vehicle_id,
      //     tag_id: tag_id,
      //     tag_name: tag_name,
      //     offer_id: offer_id,
      //     offer_name: offer_name,
      //     connect_type:
      //       state.connect_type === "brand-page"
      //         ? " brand_sticky_card"
      //         : state.connect_type,
      //     phone_number: values?.mobile,
      //     state: values?.state,
      //     is_otp_verified: state?.leadVerifiedStatus,
      //   };

      //   sendAnalytics("whatsapp_chat_initiated", analyticsPayload, "en");

      const analyticsPayload = {
        phoneNumber: values?.mobile,
        state: values?.state,
        connectType: state?.whatsAppConnectDrawerType,
        type: "whatsapp_chat_initiated",
      };

      LeadService(payload)
        .then((response) => {
          const sessionInfo = {
            leadUuid: response?.data?.uuid,
            newSession: true,
            formSubmitted: true,
            leadIntent: "MEDIUM_INTENT",
          };

          sendAnalytics("v2_whatsapp_chat_initiated", analyticsPayload, "en");

          sessionTracking(sessionInfo);
          postWhatsAppTrack(payload);

          let path = "";
          if (state?.connect_type === "brand-page") {
            path =
              `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=Yes,%20%F0%9F%91%8B%2C%20I%27m%20interested%20in%20jaasthi%20savings%20with%20electric%20gaadi%20`;
          } else {
            path =
              `https://wa.me/+${whatsappNumber}?text=Hi, I want more details about electric gadi.`;

            // vehicleInfo[brand]?.link
            //   ? vehicleInfo[brand]?.link
            //   : "https://wa.me/+918867392233?text=Hi, I'm interested in purchasing a commercial vehicle for my business!";
          }
          window.location.href = path;
          showSnackbar("We will get back to you soon!!", "success");
          setLoading(false);
          hideConnectDrawer();

          // Enable QL Lead flow
          userQlFlow(state, updateUserQlData, showReviewStoryDrawer);
        })
        .catch((error) => {
          showSnackbar(
            "Oops, something went wrong. Please try again later.",
            "error"
          );
        });
    },
  });

  return {
    form,
    loading,
  };
};

export default useConnectDrawer;
