import VideoCallIcon from "@mui/icons-material/VideoCall";
import { Box, Grid, styled, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { StateOptions } from "../constants";
import { primary } from "../constants/theme";
import { Context } from "../context/context";
import { MemoizedOTP } from "../modules/Otp/otp";
import { MemoizedButton } from "../SDK/button";
import { MemoizedSelectDropDown } from "../SDK/selectDropdown/selectDropdown";
import { checkError } from "../SDK/utils/validationHelpers";
import { sendAnalytics } from "../utils/analyticsCommon";
import useLoanFormPopupV2 from "./hooks/useDiscountDrawerV2";

const CustomLoanFormPopup = styled(Box)(({ theme }) => ({
  ".box1": {
    margin: "20px 10px",
  },
  //   ".box1": {
  //     position: "relative",
  //     // width: "500px",
  //     // height: "550px",
  //     padding: "0px",
  //     background: "#black",
  //     borderRadius: "0px 0px 15px 15px",
  //   },

  ".form-box": {
    margin: "30px 20px",
  },
  ".head-text-box": {
    margin: "20px",
    textAlign: "center",
  },
  ".check-emi-button": {
    borderRadius: "4px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&:hover": {
      // background: "#F63B63",
      border: "none",
      color: "white",
      boxShadow: "none",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },

  ".head-text": {
    fontSize: "20px",
    fontWeight: "600",
  },
  ".termsconditions": {
    fontSize: "10px",
    //marginTop: "20px",
  },

  ".input-dropdown": {
    maxHeight: "56px",
  },

  [theme.breakpoints.down("md")]: {},
}));

const LoanFormPopupV2 = ({ fixState }) => {
  const state = useContext(Context);
  const { hideLoanPopup } = useContext(Context);
  let open = state?.showLoanPopupValue;

  const { form, loading } = useLoanFormPopupV2(fixState);

  const [isCheckedModal, setIsCheckedModal] = useState(true);
  const navigate = useRouter();

  const handleCheckboxChangeModal = () => {
    setIsCheckedModal(!isCheckedModal);
  };
  //   let cacheInfo = getCookie("user_verified")
  //     ? JSON.parse(getCookie("user_verified"))
  //     : "";

  return (
    <Dialog
      PaperProps={{
        sx: { borderRadius: "0.5em", margin: "16px" },
      }}
      open={open}
      onClose={() => {
        let payload = {
          type: state?.loanPopupTrigger?.formTrigger,
          // state?.loanPopupTrigger === "v2_vehicle_Loan"
          //   ? "v2_loan_card"
          //   : state?.loanPopupTrigger === "v2_emi_value_vehicle"
          //   ? "v2_emi_value_vehicle"
          //   : "v2_view_vehicle",
        };
        sendAnalytics("v2_loan_card_lead_form_closed", payload, "en");
        hideLoanPopup();
      }}
    >
      <DialogContent style={{ padding: "0px" }}>
        <CustomLoanFormPopup>
          <Box className="box1">
            <Box className="head-text-box">
              <Typography className="head-text">
                {state?.loanPopupTrigger?.headingText
                  ? state?.loanPopupTrigger?.headingText
                  : "Enter Your Mobile Number"}
              </Typography>
            </Box>

            <form onSubmit={form.handleSubmit} className="form-schedule">
              <Box
                sx={{
                  mb: "1em",
                  mt: "1em",
                  display: "flex",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MemoizedOTP
                      style={{
                        borderRadius: "8px",
                        marginRight: "0px",
                        width: "100%",
                      }}
                      checked={isCheckedModal}
                      input_type="whatsapp"
                      form={form}
                      className="otp-styles"
                      connect_type={state.connect_type}
                      type=""
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MemoizedSelectDropDown
                      className="input-dropdown"
                      form={form}
                      id={"state"}
                      name="state"
                      required={true}
                      title={"Select State"}
                      value={fixState ? fixState : form?.values?.state}
                      optionsData={StateOptions}
                      onChange={form.handleChange}
                      error={!!checkError("state", form)}
                      helperText={form.errors.state}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  //alignItems: "baseline",
                }}
                // sx={{ mt: { xs: "12px", md: "24px" } }}
              >
                <input
                  type="checkbox"
                  label="whatsapp-checkbox"
                  style={{ width: "10px" }}
                  required={true}
                  checked={isCheckedModal}
                  onChange={handleCheckboxChangeModal}
                />

                <Typography className="termsconditions">
                  I agree to the terms and conditions below and give my consent
                  to receive calls and messages to provide further information.
                </Typography>
              </Box>

              <Box className="button-lets-chat" sx={{ mb: "1em", mt: "1em" }}>
                <MemoizedButton
                  id="lead-form"
                  className="check-emi-button"
                  disabled={!isCheckedModal}
                  type="submit"
                  // content={
                  //   state?.loanPopupTrigger?.formTrigger ===
                  //     "v2_vehicle_Loan" ||
                  //   state?.loanPopupTrigger?.formTrigger ===
                  //     "v2_emi_value_vehicle"
                  //     ? "Check EMI"
                  //     : state?.loanPopupTrigger?.formTrigger ===
                  //       "v2_video_call_clicked"
                  //     ? "Connect"
                  //     : "Get On Road Price"
                  // }
                  content={
                    state?.loanPopupTrigger?.CTAText
                      ? state?.loanPopupTrigger?.CTAText
                      : "Get On Road Price"
                  }
                  loading={loading}
                  startIcon={
                    state?.loanPopupTrigger?.formTrigger ===
                    "v2_video_call_clicked" ? (
                      <VideoCallIcon />
                    ) : (
                      ""
                    )
                  }
                  sx={{
                    width: "100%",
                    background:
                      state?.loanPopupTrigger?.formTrigger ===
                      "v2_video_call_clicked"
                        ? primary?.mainGreen
                        : primary.main,
                    borderRadius: "4px",
                    padding: "12px 8px",
                    fontSize: "16px",
                    color: "white",
                    "&:hover": {
                      background:
                        state?.loanPopupTrigger?.formTrigger ===
                        "v2_video_call_clicked"
                          ? primary?.mainGreen
                          : primary.main,
                      color: "white",
                    },
                  }}
                  handleClick={(e) => {
                    form.handleSubmit(e);
                  }}
                />
              </Box>
            </form>
          </Box>
        </CustomLoanFormPopup>
      </DialogContent>
    </Dialog>
  );
};

export default LoanFormPopupV2;
