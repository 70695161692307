import { Box, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import { sendAnalytics } from "../../../utils/analyticsCommon";

const CustomChargingStations = styled(Box)(({ theme }) => ({
  minHeight: "300px",
  ".head-text": {
    padding: "0px 15px",
    fontWeight: 600,
    fontSize: "32px",
    paddingLeft: "6px",
    display: "block",
  },
  ".sub-text": {
    fontSize: "24px",
    color: "#9C9A9D",
    fontWeight: "600",
    padding: "0px 15px",
    paddingLeft: "6px",
    textTransform: "uppercase",
    marginBottom: "4px",
    display: "block",
  },
  ".head-text-2": {
    padding: "0px 10px ",
    fontWeight: 700,
    fontSize: "24px",
    //paddingLeft: "26px",
  },
  ".image-box": {
    width: "100%",
    minHeight: "245px",
    padding: "20px 0px",
  },
  ".test-drive-image": {
    width: "100%",
    minHeight: "300px",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  ".MuiOutlinedInput-root": {
    paddingRight: "2px !important",
  },
  ".termsconditions": {
    fontSize: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    ".head-text": {
      padding: "0px",
      fontWeight: 600,
      fontSize: "27px",
      paddingLeft: "12px",
    },
    ".sub-text": {
      fontSize: "12px",
      color: "#9C9A9D",
      fontWeight: "600",
      padding: "0px",
      paddingLeft: "12px",
      marginBottom: "0",
      textTransform: "uppercase",
    },
    ".test-drive-image": {
      width: "100%",
      minHeight: "200px",
    },
  },
}));

const ChargingStations = ({ isVehicle }) => {
  const { showLoanPopup } = useContext(Context);
  const targetRef = useRef(null);
  const navigate = useRouter();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("v2_scroll_charging_station", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);
  return (
    <CustomChargingStations>
      <Box id="charging" className="main-box" ref={targetRef}>
        <Typography variant="p" className="head-text">
          Charging Stations
        </Typography>
        <Typography variant="p" className="sub-text">
          BY TURNO
        </Typography>

        {/* for vehicles page full screen loan banner */}
        <Box
          sx={{
            display: { xs: "block", md: "none", margin: "10px" },
            cursor: "pointer",
          }}
        >
          <LazyLoadImage
            fill="true"
            src={imagesWEBP?.chargingStation}
            visibleByDefault={true}
            alt="Charging Station"
            className="test-drive-image"
            onClick={() => {
              let payload = {
                type: "v2_charging_station",
              };
              sendAnalytics(
                "v2_charging_station_lead_form_opened",
                payload,
                "en"
              );
              const loanPayload = {
                formTrigger: "v2_charging_station",
                CTAText: "See Charging Stations",
                headingText: "Enter Your Mobile Number",
              };

              if (localStorage?.getItem("phoneInfo") === null) {
                showLoanPopup(loanPayload);
              } else {
                navigate.push(`/chargingStation`);
              }
            }}
          />
        </Box>
      </Box>
    </CustomChargingStations>
  );
};

export default ChargingStations;
